@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@layer base {
  html {
    font-family: Inter, system-ui, sans-serif;
  }
}
